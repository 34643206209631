<template>
  <v-container fluid class="pa-0 roles-and-permission">
    <v-row>
      <v-col
        cols="10"
        class="text-h5 font-weight-medium"
        :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-5'"
      >
        {{ $t("rolesAndPermissions") }}
      </v-col>
      <v-col cols="2" class="text-h5 font-weight-medium">
        <v-btn
          v-if="
            $vuetify.breakpoint.mdAndUp &&
            showAddRoleButton &&
            checkPermission('createRoles')
          "
          absolute
          right
          color="primary"
          class="text-none rounded-lg"
          @click="showCustomRoleDialog"
        >
          <v-icon left> mdi-plus </v-icon>
          Add Role
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-divider></v-divider>
    </v-row>
    <v-row class="ma-0 fill-height">
      <v-card
        v-resize="onResize"
        elevation="0"
        width="100%"
        :min-height="contentHeight"
      >
        <RolesAndPermissionLargeScreen
          v-if="rolesAndPermissions && $vuetify.breakpoint.mdAndUp"
          :roles-and-permissions="rolesAndPermissions"
          :role-user-count-map="roleUserCountMap"
          @updatePermissions="updatePermissions"
          @editRole="editRole"
          @deleteRole="deleteRole"
        />
        <RolesAndPermissionMobile
          v-if="rolesAndPermissions && $vuetify.breakpoint.smAndDown"
          :roles-and-permissions="rolesAndPermissions"
          :role-user-count-map="roleUserCountMap"
          @updatePermissions="updatePermissions"
          @editRole="editRole"
          @deleteRole="deleteRole"
        />
        <CustomRoleDialog
          :dialog="customRoleDialog"
          @createRole="manageCustomRole"
          @closeDialog="closeDialog"
        />
        <EditRoleModal
          :dialog="editRoleDialog"
          :role="roleToManage"
          @editRole="manageCustomRole"
          @closeDialog="editRoleDialog = false"
        />
        <DeleteRoleModal
          :dialog="deleteRoleDialog"
          :role-name="roleToManage"
          @deleteRole="manageCustomRole"
          @closeDialog="deleteRoleDialog = false"
        />
      </v-card>
    </v-row>
    <v-bottom-navigation
      v-if="
        $vuetify.breakpoint.smAndDown &&
        showAddRoleButton &&
        checkPermission('createRoles')
      "
      v-model="bottomNavbar"
      fixed
      horizontal
      height="70"
      class="px-2 py-4"
      ><v-row class="px-4 py-2">
        <v-btn
          color="primary"
          class="text-none rounded-lg text-h6 white--text"
          block
          height="50"
          @click="showCustomRoleDialog"
          >{{ $t("addRole") }}<v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-row></v-bottom-navigation
    >
  </v-container>
</template>

<script>
import rolesAndPermissionsDefaults from "@/static/rolesAndPermissionsDefaults.json";
import UserDataMixin from "@/mixins/UserData";
export default {
  name: "Roles",
  components: {
    RolesAndPermissionLargeScreen: () =>
      import("./RolesAndPermissionLargeScreen.vue"),
    RolesAndPermissionMobile: () => import("./RolesAndPermissionMobile.vue"),
    CustomRoleDialog: () => import("./CustomRoleDialog.vue"),
    EditRoleModal: () => import("./EditRoleModal.vue"),
    DeleteRoleModal: () => import("./DeleteRoleModal.vue"),
  },
  mixins: [UserDataMixin],
  props: {},
  data() {
    return {
      contentHeight: "",
      customRoleDialog: false,
      editRoleDialog: false,
      deleteRoleDialog: false,
      isEditRoleMode: false,
      bottomNavbar: true,
      roleToManage: "",
      roleUserCountMap: {},
    };
  },
  computed: {
    rolesAndPermissions() {
      const company = this.$store.state.Company.company;
      if (company && company.options) {
        this.getUsers(this.$store.state.Company.company.company_id);
        return this.$store.getters.rolesAndPermissionsTree(this) || {};
      } else {
        return {};
      }
    },
    showAddRoleButton() {
      const user = this.$store.state.User.user;
      if (user.role.includes("admin")) {
        //TODO change this based on permission
        return true;
      }
      return false;
    },
  },
  created() {
    this.setContentHeight();
  },
  methods: {
    async getUsers(company_id) {
      const userData = await this.$store.dispatch("getCompanyUsers", {
        company_id: company_id,
      });
      if (userData && userData.status !== "error") {
        userData.forEach((item) => {
          if (!this.roleUserCountMap[item.persona])
            this.roleUserCountMap[item.persona] = 0;
          this.roleUserCountMap[item.persona] =
            this.roleUserCountMap[item.persona] + 1;
        });
      }
    },
    onResize() {
      this.setContentHeight();
    },
    setContentHeight() {
      const appBarHeight = 64;
      const titleHeight = 56;
      const titlePadding = 12;
      const footerHeight = 20;
      this.contentHeight =
        window.innerHeight -
        (appBarHeight + titleHeight + titlePadding + footerHeight);
    },
    closeDialog() {
      this.customRoleDialog = false;
    },
    showCustomRoleDialog() {
      this.customRoleDialog = true;
    },
    addCustomRole() {
      this.isEditRoleMode = false;
      this.isDeleteMode = false;
      this.isAddMode = true;
      this.showCustomRoleDialog();
    },
    editRole(role) {
      this.isDeleteMode = false;
      this.isAddMode = false;
      this.isEditRoleMode = true;
      this.roleToManage = role;
      this.editRoleDialog = true;
    },
    deleteRole(role) {
      this.isAddMode = false;
      this.isEditRoleMode = false;
      this.isDeleteMode = true;
      this.roleToManage = role;
      this.deleteRoleDialog = true;
    },
    async manageCustomRole(roleName) {
      const customRolesAndPermissions = JSON.parse(
        JSON.stringify(rolesAndPermissionsDefaults.permissionSet),
      );
      const company = this.$store.state.Company.company;
      const options = Object.assign({}, company.options);
      if (this.isEditRoleMode) {
        options.rolesAndPermissions[roleName] =
          options.rolesAndPermissions[this.roleToManage].slice();
        delete options.rolesAndPermissions[this.roleToManage];
      } else if (this.isDeleteMode) {
        delete options.rolesAndPermissions[this.roleToManage];
      } else {
        options.rolesAndPermissions[roleName] = [];
      }
      const CompanyStatus = await this.$store.dispatch("updateCompany", {
        company_id: company.company_id,
        options: options,
      });
      if (CompanyStatus.status === "error") {
        console.log("error in custom role creation");
      } else {
        this.$store.commit("setCompanyData", { options: options });
        if (this.isDeleteMode) {
          if (this.$vuetify.breakpoint.mdAndUp) {
            this.$el.getElementsByClassName("roles-tab")[0].click();
          }
          this.isDeleteMode = false;
        }
      }
    },
    async updatePermissions(updateObject) {
      const company = this.$store.state.Company.company;
      const options = Object.assign({}, company.options);
      Object.keys(options.rolesAndPermissions).forEach((role) => {
        if (role === updateObject.role) {
          options.rolesAndPermissions[role] = updateObject.activeNodes;
        }
      });
      const CompanyStatus = await this.$store.dispatch("updateCompany", {
        company_id: company.company_id,
        options: options,
      });
      if (CompanyStatus.status === "error") {
        console.log("error in custom role update");
      } else {
        this.$store.commit("setCompanyData", { options: options });
      }
    },
  },
};
</script>
<style lang="scss"></style>
