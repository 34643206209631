var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 roles-and-permission", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "text-h5 font-weight-medium",
              class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8" : "pl-5",
              attrs: { cols: "10" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("rolesAndPermissions")) + " ")]
          ),
          _c(
            "v-col",
            { staticClass: "text-h5 font-weight-medium", attrs: { cols: "2" } },
            [
              _vm.$vuetify.breakpoint.mdAndUp &&
              _vm.showAddRoleButton &&
              _vm.checkPermission("createRoles")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "text-none rounded-lg",
                      attrs: { absolute: "", right: "", color: "primary" },
                      on: { click: _vm.showCustomRoleDialog },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(" mdi-plus "),
                      ]),
                      _vm._v(" Add Role "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1),
      _c(
        "v-row",
        { staticClass: "ma-0 fill-height" },
        [
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.onResize,
                  expression: "onResize",
                },
              ],
              attrs: {
                elevation: "0",
                width: "100%",
                "min-height": _vm.contentHeight,
              },
            },
            [
              _vm.rolesAndPermissions && _vm.$vuetify.breakpoint.mdAndUp
                ? _c("RolesAndPermissionLargeScreen", {
                    attrs: {
                      "roles-and-permissions": _vm.rolesAndPermissions,
                      "role-user-count-map": _vm.roleUserCountMap,
                    },
                    on: {
                      updatePermissions: _vm.updatePermissions,
                      editRole: _vm.editRole,
                      deleteRole: _vm.deleteRole,
                    },
                  })
                : _vm._e(),
              _vm.rolesAndPermissions && _vm.$vuetify.breakpoint.smAndDown
                ? _c("RolesAndPermissionMobile", {
                    attrs: {
                      "roles-and-permissions": _vm.rolesAndPermissions,
                      "role-user-count-map": _vm.roleUserCountMap,
                    },
                    on: {
                      updatePermissions: _vm.updatePermissions,
                      editRole: _vm.editRole,
                      deleteRole: _vm.deleteRole,
                    },
                  })
                : _vm._e(),
              _c("CustomRoleDialog", {
                attrs: { dialog: _vm.customRoleDialog },
                on: {
                  createRole: _vm.manageCustomRole,
                  closeDialog: _vm.closeDialog,
                },
              }),
              _c("EditRoleModal", {
                attrs: { dialog: _vm.editRoleDialog, role: _vm.roleToManage },
                on: {
                  editRole: _vm.manageCustomRole,
                  closeDialog: function ($event) {
                    _vm.editRoleDialog = false
                  },
                },
              }),
              _c("DeleteRoleModal", {
                attrs: {
                  dialog: _vm.deleteRoleDialog,
                  "role-name": _vm.roleToManage,
                },
                on: {
                  deleteRole: _vm.manageCustomRole,
                  closeDialog: function ($event) {
                    _vm.deleteRoleDialog = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$vuetify.breakpoint.smAndDown &&
      _vm.showAddRoleButton &&
      _vm.checkPermission("createRoles")
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "px-2 py-4",
              attrs: { fixed: "", horizontal: "", height: "70" },
              model: {
                value: _vm.bottomNavbar,
                callback: function ($$v) {
                  _vm.bottomNavbar = $$v
                },
                expression: "bottomNavbar",
              },
            },
            [
              _c(
                "v-row",
                { staticClass: "px-4 py-2" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none rounded-lg text-h6 white--text",
                      attrs: { color: "primary", block: "", height: "50" },
                      on: { click: _vm.showCustomRoleDialog },
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("addRole"))),
                      _c("v-icon", [_vm._v("mdi-plus")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }